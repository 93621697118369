import React from "react"
import { Link } from "gatsby"
import * as styles from "./blog.module.scss"
const BlogTags = ({ tagsArr }) => {
  return (
    <article className={`${styles.blogTags} container`}>
      <div className={styles.tagsContainer}>
        {tagsArr.length ? <span>tags: </span> : undefined}
        {tagsArr.map((tag, i) => (
          <Link
            className="primary-button"
            key={tag.id}
            to={`/blog/tags/${tag.elements.slug.value}`}
          >
            {tag.elements.tag.value}
            {i === tagsArr.length - 1 ? "" : ", "}
          </Link>
        ))}
      </div>
    </article>
  )
}

export default BlogTags
